<template>
<div>
    <b-row>
      <b-col lg="6" md="6" sm="6" class="heading-title">
        <h4>Dashboard</h4>
          <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
          <b-breadcrumb-item active> Home </b-breadcrumb-item>
        </b-breadcrumb>
      </b-col>
      <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
    </b-row>
    <br>
    {{ role_type }}
  <div v-if="role_type == 'vendor'">
    <VendorD></VendorD>
  </div>
   <div v-if="role_type == 'admin'">
    <AdminD></AdminD>
  </div>
</div>
</template>
<script>
import  VendorD from "./VendorD";
import AdminD from "./AdminD";
export default {
  name: "Dashboard",
  data: () => ({
    title: "Dashboard",
    role_type: JSON.parse(localStorage.getItem("loggedUserData")).role_type,
  }),
  components: {
  VendorD , AdminD
  },
};
</script>
<style scoped>

</style>
<template>
  <div>
    
    <b-row>
      <b-col lg="8" cols="12" md="12" class="d-flex align-items-stretch">
        <b-row>
          <b-col lg="6" cols="12" md="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>Confirmed Bookings</span>
                  <h4>{{ booking_count.total_confimed_booking }}</h4>
                </div>
                <div class="ml-auto">
                  <div>
                    <vue-apex-charts
                      type="bar"
                      height="70px"
                      width="120px"
                      :options="referralChart.chartOptions"
                      :series="referralChart.series"
                    ></vue-apex-charts>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col lg="6" cols="12" md="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>Canceled Bookings</span>
                  <h4>{{ booking_count.total_cancelled_booking }}</h4>
                </div>
                <div class="ml-auto">
                  <div>
                    <vue-apex-charts
                      type="bar"
                      height="70px"
                      width="120px"
                      :options="referralChart.chartOptions"
                      :series="referralChart.series"
                    ></vue-apex-charts>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col lg="6" cols="12" md="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>Blocked Bookings</span>
                  <h4>{{ booking_count.total_blocked_booking }}</h4>
                </div>
                <div class="ml-auto">
                  <div>
                    <vue-apex-charts
                      type="bar"
                      height="70px"
                      width="120px"
                      :options="referralChart.chartOptions"
                      :series="referralChart.series"
                    ></vue-apex-charts>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col lg="6" cols="12" md="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>Refunded Bookings</span>
                  <h4>0</h4>
                </div>
                <div class="ml-auto">
                  <div>
                    <vue-apex-charts
                      type="bar"
                      height="70px"
                      width="120px"
                      :options="referralChart.chartOptions"
                      :series="referralChart.series"
                    ></vue-apex-charts>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="4" cols="12" md="12" class="d-flex align-items-stretch">
        <b-card no-body class="bg-info mb-4 w-100">
          <h4 class="card-title text-white p-3 mb-0">
            <span class="mb-2 d-block">Visit Analytics</span>
            <i class="ti-arrow-up"></i> {{ booking_count.visitors_count }}
          </h4>
          <vue-apex-charts
            type="area"
            height="112px"
            :options="viewChart.chartOptions"
            :series="viewChart.series"
          ></vue-apex-charts>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <!-- chart -->
      <b-row>
        <b-col lg="12" cols="12" md="12" class="d-flex align-items-stretch">
          <b-card class="w-100">
            <div class="text-left">
              <h3 class="font-weight-light">Monthly Booking Status</h3>
            </div>
            <div class="mt-1">
              <vue-apex-charts
                ref="booking_charts"
                type="bar"
                height="200"
                :options="booking_chart.chartOptions"
                :series="booking_chart.series"
              ></vue-apex-charts>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <div class="card-body border-top">
              <b-row>
                <b-col cols="12" lg="3" md="6">
                  <div class="d-flex align-items-center">
                    <div class="mr-2">
                      <span class="display-4 text-info">
                        <i class="mdi mdi-cash-100"></i>
                      </span>
                    </div>
                    <div>
                      <span id="money_l" class="text-dark">Earnings</span>
                      <h5 class="font-medium mb-0">343434323</h5>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" lg="3" md="6">
                  <div class="d-flex align-items-center">
                    <div class="mr-2">
                      <span class="display-4 text-warning">
                        <i class="mdi mdi-star-circle"></i>
                      </span>
                    </div>
                    <div>
                      <span id="money_l" class="text-dark">Expenses</span>
                      <h5 class="font-medium mb-0">343434323</h5>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" lg="3" md="6">
                  <div class="d-flex align-items-center">
                    <div class="mr-2">
                      <span class="display-4 text-primary">
                        <i class="mdi mdi-cash-multiple"></i>
                      </span>
                    </div>
                    <div>
                      <span id="money_l" class="text-dark">Net Income</span>
                      <h5 class="font-medium mb-0">343434323</h5>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" lg="3" md="6">
                  <div class="d-flex align-items-center">
                    <div class="mr-2">
                      <span class="display-4 text-success">
                        <i class="mdi mdi-currency-inr"></i>
                      </span>
                    </div>
                    <div>
                      <span id="money_l" class="text-dark">Balance</span>
                      <h5 class="font-medium mb-0">343434323</h5>
                    </div>
                  </div>
                </b-col>
              </b-row>
      </div>
    </b-card>
    <br />

    <!-- tables -->
    <b-row>
      <b-col
        lg="12"
        cols="12"
        md="12"
        sm="12"
        class="d-flex align-items-stretch"
      >
        <b-card class="mb-4 w-100">
          <div class="text-center">
            <b-tabs
              active-nav-item-class="text-uppercase text-primary"
              active-tab-class="text-success"
              content-class="mt-3"
            >
              <b-tab title="Recent Bookings" active>
                <b-table
                  :items="get_recent_booking"
                  :fields="fields"
                  responsive
                  hover
                  bordered
                  show-empty
                  empty-text="No Data Available"
                  style="max-width: 100%"
                >
                </b-table>
              </b-tab>
              <b-tab title="Upcoming Bookings">
                <b-table
                  :items="get_upcoming_booking"
                  :fields="fields"
                  responsive
                  hover
                  bordered
                  show-empty
                  empty-text="No Data Available"
                  style="max-width: 100%"
                >
                </b-table>
              </b-tab>
            </b-tabs>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// -----------------------------------------
// Import Components Here
// -----------------------------------------

// -----------------------------------------
// Export Here
// -----------------------------------------
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
export default {
  name: "Dashboard",

  data: () => ({
    title: "Dashboard",
    is_first_login:false,
    booking_chart: {
      series: [
        {
          data: [],
          name: "Canceled Booking",
        },
        {
          data: [],
          name: "Confirmed Booking",
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 430,
        },
        plotOptions: {
          bar: {
            vertical: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
      },
    },
    line_chart: {
      series: [
        {
          name: "Booked",
          data: [28, 29, 33, 36, 32, 32, 33],
        },
        {
          name: "Blocked",
          data: [12, 11, 14, 18, 17, 13, 13],
        },
        {
          name: "Cancelled",
          data: [12, 11, 14, 19, 27, 13, 16],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#0dcaf0", "#0d6efd", "#198754"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          title: {
            text: "",
          },
        },
        yaxis: {
          title: {
            text: "",
          },
          min: 5,
          max: 40,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    },
    searchChart: {
      series: [14, 15],
      chartOptions: {
        labels: ["Search Traffic", "Free"],
        dataLabels: {
          enabled: false,
        },
        grid: {
          padding: {
            left: 0,
            right: 0,
          },
        },
        chart: {},
        plotOptions: {
          pie: {
            donut: {
              size: "85px",
            },
          },
        },
        stroke: {
          width: 0,
        },
        legend: {
          show: false,
        },
        colors: ["#0dcaf0", "#0d6efd"],
        tooltip: {
          enabled: false,
          theme: "dark",
        },
      },
    },
    fields: [
      {
        key: "customer_name",
        label: "Customer Name",
        sortable: true,
      },
      {
        key: "venue_name",
        label: "Venue Name",
        sortable: true,
      },
      {
        key: "date",
        label: "Booking Date",
        sortable: true,
      },
      {
        key: "price",
        label: "Price",
        sortable: true,
      },
      {
        key: "booked_on",
        label: "Booked on",
        sortable: true,
      },
      {
        key: "booking_status",
        label: "Booking Status",
        sortable: true,
      },
      {
        key: "booking_type",
        label: "Booking Type",
        sortable: true,
      },
    ],
    walletChart: {
      series: [
        {
          name: "Balance",
          data: [59, 65, 45, 50, 42, 40],
        },
      ],
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        colors: ["#4fc3f7"],
        chart: {
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        markers: {
          size: 3,
          strokeColor: "transparent",
        },
        fill: {
          type: "solid",
          opacity: 0,
        },
        xaxis: {
          type: "numeric",
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: { show: false },
        },
        yaxis: [
          {
            y: 0,
            offsetX: 0,
            offsetY: 0,
            padding: { left: 0, right: 0 },
          },
        ],
        tooltip: {
          x: { show: false },
          theme: "dark",
        },
      },
    },
    referralChart: {
      series: [
        {
          name: "Net Profit",
          data: [6, 10, 9, 11, 9, 10, 11],
        },
      ],

      chartOptions: {
        colors: ["#2962FF"],
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "flat",
            columnWidth: "30%",
          },
        },
        chart: {
          fontFamily: '"Nunito Sans",sans-serif',
          foreColor: "#a1aab2",
          toolbar: {
            show: false,
          },
          sparkline: { enabled: true },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          show: false,
          labels: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        grid: {
          show: false,
          stroke: {
            show: false,
          },
          padding: {
            top: 0,
            bottom: 0,
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: false,
          theme: "dark",
        },
      },
    },
    viewChart: {
      series: [
        {
          name: "Views",
          data: [28, 40, 36, 52, 38, 60, 55],
        },
      ],
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        colors: ["#fff"],
        chart: {
          fontFamily: '"Nunito Sans",sans-serif',
          foreColor: "#a1aab2",
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0.5,
            stops: [0, 90, 100],
          },
          colors: ["#fff", "#4fc3f7"],
        },
        stroke: {
          curve: "straight",
          width: 1,
          colors: ["rgba(255,255,255,.2)"],
        },
        tooltip: {
          enabled: false,
          theme: "dark",
        },
      },
    },
    booking_count: [],
    get_recent_booking: [],
    get_upcoming_booking: [],
  }),
  components: {
    VueApexCharts,
  },
  methods: {
    getBookingCount() {
      axios.post("/api/get_vendor_dashboard_count").then((resp) => {
        if (resp.data.status_code == 200) {
          if (resp.data.status) {
            this.booking_count = resp.data.total_counts;
          }
        }
      });
    },
    getUserDetails() {
      axios.get("/api/get_profile").then((resp) => {
        if (resp.data.status_code == 200 && resp.data.status) {
          this.$store.commit("SET_KYC_NOTIFICATION", resp.data.users);
          if(resp.data.users.is_first_login == null){
            this.is_first_login = true;

            axios.post("/api/update_first_login").then(() => {
              // console.log(resp);
            });
          }
        }
      });
    },
    getBookingList() {
      axios.post("/api/get_vendor_bookings").then((resp) => {
        if (resp.data.status_code == 200) {
          this.get_recent_booking = [];
          this.get_upcoming_booking = [];
          if (resp.data.status) {
            // for incoming
            for (
              var i = 0;
              i < resp.data.bookings.get_recent_booking.length;
              i++
            ) {
              this.get_recent_booking.push({
                date:
                  resp.data.bookings.get_recent_booking[i].from_date +
                  " to " +
                  resp.data.bookings.get_recent_booking[i].to_date,
                booked_on: resp.data.bookings.get_recent_booking[i].booked_on,
                customer_name:
                  resp.data.bookings.get_recent_booking[i].get_customer
                    .first_name +
                  " " +
                  resp.data.bookings.get_recent_booking[i].get_customer
                    .last_name,
                venue_name:
                  resp.data.bookings.get_recent_booking[i].child_venue
                    .place_name.substring(0,15),
                price:
                  "Rs. " +
                  resp.data.bookings.get_recent_booking[i].total_booking_price,
                booking_status: resp.data.bookings.get_recent_booking[i].status,
                booking_type:
                  resp.data.bookings.get_recent_booking[i].booking_type,
              });
            }

            // for upcoming

            for (
              var a = 0;
              a < resp.data.bookings.get_upcoming_booking.length;
              a++
            ) {
              this.get_upcoming_booking.push({
                date:
                  resp.data.bookings.get_upcoming_booking[a].from_date +
                  " to " +
                  resp.data.bookings.get_upcoming_booking[a].to_date,
                booked_on: resp.data.bookings.get_upcoming_booking[a].booked_on,
                customer_name:
                  resp.data.bookings.get_upcoming_booking[a].get_customer
                    .first_name +
                  " " +
                  resp.data.bookings.get_upcoming_booking[a].get_customer
                    .last_name,
                venue_name:
                  resp.data.bookings.get_upcoming_booking[a].child_venue
                    .place_name,
                price:
                  "Rs. " +
                  resp.data.bookings.get_upcoming_booking[a]
                    .total_booking_price,
                booking_status:
                  resp.data.bookings.get_upcoming_booking[a].status,
                booking_type:
                  resp.data.bookings.get_upcoming_booking[a].booking_type,
              });
            }
          }
        }
      });
    },
    getBookingMonthly() {
      axios.post("/api/get_monthly_bookings_status").then((resp) => {
        if (resp.data.status_code == 200) {
          if (resp.data.status) {
            var months = resp.data.booking_data.series_data_arr;

            var cancel = resp.data.booking_data.total_cancelled_bookings;

            var complete = resp.data.booking_data.total_completed_bookings;

            this.$refs.booking_charts.refresh(
              (this.booking_chart.chartOptions.xaxis.categories = months),
              (this.booking_chart.series[0].data = cancel),
              (this.booking_chart.series[1].data = complete)
            );
          }
        }
      });
    },
  },
  mounted() {
   // this.getUserDetails();
   // this.getBookingMonthly();
    //this.getBookingCount();
    //this.getBookingList();
  },
};
</script>
<style scoped>
#money_l {
  font-size: 16px;
}
#dount_set {
  padding-top: 20px;
  padding-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .border-bottom {
    border-bottom: none !important;
  }
}
@media screen and (max-width: 576px) {
  .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
}
</style>